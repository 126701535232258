import React, {useEffect} from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
import Seo from '@components/utility/SEO';
import { KfAnchorLink } from '@klickinc/kf-react-components';
import Cta from '@components/content/Cta';
import Hero from '@components/content/hero/Hero';
import Image from '@components/utility/Image';
import Loadable from '@loadable/component';
import './styles.scss';

const WhizzbangLine = Loadable(() => import('@components/content/WhizzbangLine'));
const DosingCalc = Loadable(() => import('@components/content/dosing-calc/DosingCalc'));

const DosingAndAdministration = () => {

	const runScreenshots = () => {
		if (typeof window !== 'undefined') {
			const url = window.location.href;

			if (url.includes('ssLbsSelected')) {
				document.getElementById('lbs').checked = true;
			}

			if (url.includes('ssKgSelected')) {
				document.getElementById('kg').checked = true;
			}

			if (url.includes('ssWeightRangeDropdown')) {
				const weightDropdownBtn = document.getElementById('weightRange').value;
				if (weightDropdownBtn) weightDropdownBtn.value = weightDropdownBtn.value[2];
			}

			if (url.includes('ssCalculateBtn')) {
				const calculateBtn = document.getElementById('weightRange');

				if (calculateBtn) calculateBtn.click();
			}
		}
	};

	useEffect(() => {
		if (typeof window !== 'undefined') {
			if (window.location.href.indexOf('ed=1') > -1) {
				runScreenshots();
			}
		}
	}, []);

	return (
		<Layout>
			<Seo title='Dosing & Administration | EMFLAZA® (deflazacort)' description='EMFLAZA® (deflazacort) is available in 2 formulations: tablet & liquid. See available dosage strengths and calculate dosing for your patients. View full Prescribing Information & Important Safety Information.' />
			<div className="md:container 2xl:mx-auto page--dosing xl:pr-48">
				<Hero
					objectFit='contain'
					heroImage='hero-bg-dosing.png'
					heroImageMobile='hero-bg-dosing-mobile.png'
					breadcrumbs={['Dosing & Administration']}
					title={<span>Treating with convenient once-daily dosing<sup>3</sup></span>}
					addedClass='hero-bg'
				>
					EMFLAZA is available in 2 formulations (tablet and liquid)
					that can be taken with or without food. Recommended dosing
					(approximately 0.9 mg/kg/day) for both formulations.
					Tablets are available in 4 different strengths.<sup className='text-xxs'>3</sup>
				</Hero>

				<div className="px-8 xl:max-w-3xl text-emflaza-blue-600 md:px-0">
					<div className="my-12 mb-56 md:my-28 md:mb-56">
						<p className="mb-3">Below you will find helpful information about how to administer EMFLAZA, dosing considerations, and potential drug interactions.</p>
						<p className="mb-3">Use this simple dosing assistant to help EMFLAZA patients stay up to date on treatment. Just select your patient's weight range and calculate.</p>

						<DosingCalc></DosingCalc>
					</div>

					{/* Download the full dosing table START */}
					<div className="relative">
						<div className="bg-download absolute -top-40 -left-40 2xl:-left-1/3 2.5xl:left-[-45%] 3xl:-left-2/4">
							<Image imageName='blue-bg.png' alt=""></Image>
							<div className="relative">
								<div className="absolute -top-32 md:-top-36 left-40 md:left-44">
									<div className="flex items-center space-x-3">
										<div className='min-w-[2.5rem]'>
											<Image imageName='download-icon.png' imageNameMobile='download-icon.png' alt=""></Image>
										</div>
										<KfAnchorLink url='/dosing-chart.pdf' download="EMFLAZA-DOSING-Chart" target="_blank" linkClass='text-xl link link--blue md:text-2xl'>Download the<br/> full dosing table</KfAnchorLink>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* Download the full dosing table END */}


					<div className="bottom-section">
						<WhizzbangLine imageName='whizzbang-line-1.png' />

						<div className="flex flex-wrap items-center">
							<div className="flex-1 text-center md:mt-2">
								<Image imageName='pills.png' alt=""></Image>
							</div>
							<div className="max-w-lg">
								<h2 className="pb-3 mt-10 font-bold md:pb-0 md:py-3 font-roboto md:mt-0">EMFLAZA tablets are available in 4 different strengths <br className='hidden md:inline' /> (6 mg, 18 mg, 30 mg, 36 mg)<sup>3</sup></h2>
								<ul>
									<li>EMFLAZA can be taken with or without food</li>
									<li>Tablets may be given whole or crushed and can be taken immediately after mixing with applesauce</li>
									<li>Prescribed dosage strengths can be achieved by taking multiple tablets in combination, rounding up to the nearest dose amount</li>
								</ul>
							</div>
						</div>

						<div className="flex flex-wrap items-center md:space-y-10">
							<div className="flex-1 mt-6 text-center md:mt-12">
								<Image imageName='bottle.png' alt=""></Image>
							</div>
							<div className="max-w-lg">
								<h2 className="pb-3 mt-10 font-bold md:pb-0 md:py-3 font-roboto">EMFLAZA is also available in liquid form<sup>3</sup></h2>
								<ul>
									<li>Round up to the nearest tenth of a milliliter (mL)</li>
									<li>13 mL in a 30-mL bottle with two 1-mL oral dispensers included</li>
									<li>Mix EMFLAZA well with 3-4 oz of juice or milk and give immediately</li>
									<li>Store at room temperature</li>
									<li>Replace cap tightly on bottle and clean oral dispenser after each use</li>
									<li>Discard any unused EMFLAZA after 1 month of first opening the bottle</li>
									<li>Do not mix EMFLAZA with grapefruit juice; EMFLAZA should not be taken with grapefruit or grapefruit juice</li>
								</ul>
							</div>
						</div>

						<h2 className="pb-3 mt-10 font-bold uppercase md:pb-0 md:py-3 font-roboto">Important Dosing Considerations<sup>3</sup></h2>
						<p>Patients should not stop taking EMFLAZA abruptly or without first checking with their healthcare provider. You may need to gradually reduce the dose rather than discontinue treatment altogether.</p>
						<ul>
							<li>The dosage of EMFLAZA must be decreased gradually if the drug has been administered for more than a few days</li>
							<li>Weight gain should be assessed to determine whether the dose is appropriate</li>
						</ul>

						<h2 className="pb-3 mt-10 font-bold md:pb-0 md:py-3 font-roboto">Let your patients know about any potential drug interactions associated with EMFLAZA<sup>3</sup></h2>
						<ul>
							<li>If the patient is also taking a moderate or strong CYP3A4 inhibitor, give one-third of the recommended dosage <br className='hidden md:inline' /> of EMFLAZA</li>
							<li>Avoid use of strong or moderate CYP3A4 inducers with EMFLAZA because they may reduce efficacy</li>
							<li>Patients receiving corticosteroids, including EMFLAZA, and concomitant therapy with neuromuscular blocking drugs (eg, pancuronium) may be at increased risk of developing an acute myopathy</li>
						</ul>

						<h2 className="pb-3 mt-10 font-bold md:pb-0 md:py-3 font-roboto">Dose adjustments may be needed as patients grow<sup>3</sup></h2>
						<ul>
							<li>Assess bodyweight to determine appropriate dosing</li>
						</ul>

					</div>
					<div className="flex flex-wrap mt-20 mb-8 space-y-5 md:space-y-0 md:space-x-5">
						<Cta ctaUrl='/getting-started/prescription-start-form' iconUrl={'cta-prescription.png'} ctaText={<span>Prescription <br /> start form</span>} addedClass='cta flex-1'/>
						<Cta ctaUrl='/getting-started/switch-considerations' iconUrl={'cta-s-icon.png'} ctaText='Switching corticosteroids' addedClass='cta flex-1'/>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default DosingAndAdministration;
